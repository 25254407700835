import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720.000000 697.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
         <path d="M0 3485 l0 -3485 40 0 40 0 0 3485 0 3485 -40 0 -40 0 0 -3485z"/>
         <path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2985 1167 c139 -37 195 -57 195 -69 0 -11 -18 -10 -25 2 -3 6 -13 10
-21 10 -20 0 -17 -20 4 -20 15 -1 15 -2 -2 -15 -17 -13 -18 -15 -3 -15 11 0
17 -9 18 -25 1 -14 2 -31 3 -37 1 -7 -3 -16 -9 -20 -6 -5 -2 -8 8 -8 10 0 15
-5 12 -10 -4 -6 -11 -8 -16 -5 -5 4 -9 1 -9 -4 0 -6 9 -11 20 -11 11 0 20 -5
20 -11 0 -7 -9 -9 -22 -5 -329 95 -664 106 -961 31 -259 -66 -515 -208 -712
-395 -292 -277 -469 -619 -529 -1021 l-13 -84 -7 85 c-13 148 22 377 86 565
151 444 517 821 964 995 82 32 283 87 369 101 28 4 151 6 275 4 220 -3 228 -4
355 -38z m195 -97 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0
9 -4 9 -10z m-1053 -530 c85 -12 202 -61 240 -102 41 -45 63 -100 63 -161 0
-66 -15 -108 -55 -155 l-28 -33 38 -37 c108 -105 115 -285 15 -381 -84 -80
-182 -101 -482 -101 l-228 0 0 483 c0 266 3 487 7 490 10 11 344 8 430 -3z
m993 -15 c162 -51 224 -122 225 -255 0 -72 -2 -78 -38 -128 l-39 -52 45 -50
c57 -64 81 -132 74 -210 -10 -111 -74 -187 -194 -230 -64 -22 -84 -24 -325
-28 l-258 -4 0 484 c0 267 3 488 8 492 4 5 106 6 227 3 171 -3 232 -8 275 -22z
m-989 -1159 l-3 -89 23 7 c55 17 136 18 167 2 55 -28 75 -69 80 -160 3 -70 0
-87 -20 -126 -42 -81 -113 -107 -210 -78 -32 9 -41 9 -48 -1 -6 -10 -23 -11
-71 -6 -53 6 -60 9 -46 20 15 11 17 38 17 244 0 199 -2 231 -15 231 -15 0 -22
20 -7 20 4 1 30 7 57 15 80 22 81 22 76 -79z m1569 -165 c0 -231 2 -260 16
-265 28 -11 6 -20 -53 -24 -36 -2 -59 1 -63 8 -6 10 -16 9 -45 0 -49 -16 -130
-8 -163 17 -49 37 -67 80 -67 164 0 66 4 81 28 118 44 67 79 83 165 78 l72 -4
0 57 c0 51 -3 58 -22 65 -19 5 -11 10 42 25 36 10 71 19 78 19 9 1 12 -57 12
-258z m214 0 c1 -238 2 -253 20 -267 18 -12 9 -14 -79 -14 -93 0 -98 1 -77 16
22 15 22 19 22 239 0 222 0 224 -22 236 -21 11 -17 14 47 30 39 9 74 16 79 15
6 -1 10 -105 10 -255z m-3144 209 c78 -42 93 -119 36 -176 -19 -19 -41 -34
-50 -34 -27 0 -18 -17 13 -23 17 -4 45 -20 63 -36 29 -26 33 -35 33 -80 0 -41
-5 -55 -30 -81 -44 -46 -106 -60 -264 -59 -127 0 -134 1 -113 16 22 15 22 19
22 234 0 208 -1 219 -20 231 -11 7 -20 17 -20 22 0 14 301 2 330 -14z m700
-71 l0 -49 40 0 c22 0 40 -4 40 -10 0 -5 -18 -10 -40 -10 l-41 0 3 -157 3
-158 30 1 c17 0 38 4 48 8 29 13 11 -18 -22 -37 -44 -26 -114 -22 -148 7 l-28
24 -4 156 c-3 145 -4 156 -22 156 -29 0 -23 17 9 23 16 3 47 26 72 55 25 26
48 47 53 45 4 -2 7 -26 7 -54z m396 -55 c49 -23 76 -65 77 -122 l2 -47 -117
-3 -117 -3 6 -37 c7 -49 38 -98 66 -106 41 -11 82 -6 116 15 41 25 52 17 21
-15 -91 -98 -276 -61 -314 62 -11 34 -9 124 4 157 35 93 164 142 256 99z m714
-147 c0 -113 4 -167 12 -175 17 -17 59 -15 86 4 21 14 22 22 22 144 0 112 -2
130 -17 139 -24 14 -5 27 55 36 24 4 51 9 58 12 12 4 14 -26 14 -175 0 -154 2
-181 16 -186 32 -12 4 -21 -60 -18 -49 3 -66 7 -66 18 0 18 2 18 -45 -6 -77
-39 -164 -19 -185 44 -6 19 -10 86 -9 151 1 103 -1 118 -17 130 -16 11 -17 14
-4 19 12 4 73 15 133 25 4 0 7 -72 7 -162z m430 156 c0 -5 4 -14 8 -21 7 -10
18 -8 53 7 94 43 163 16 180 -69 4 -25 8 -94 9 -154 0 -92 3 -111 18 -122 14
-11 3 -13 -78 -13 -81 0 -92 2 -77 13 15 12 17 32 17 160 0 144 0 147 -24 157
-18 9 -31 7 -60 -6 l-36 -17 0 -134 c0 -121 2 -136 20 -154 l20 -20 -90 0
c-49 0 -90 3 -90 6 0 3 7 12 15 20 23 23 22 266 -1 289 -20 20 -19 21 47 44
62 22 69 24 69 14z m1249 -11 c51 -25 75 -63 75 -119 l1 -48 -112 -3 -113 -3
0 -30 c0 -54 28 -98 70 -110 47 -14 99 -5 128 22 16 15 22 16 22 5 0 -23 -66
-74 -105 -82 -95 -18 -180 19 -216 94 -31 63 -23 167 16 218 52 68 161 94 234
56z m378 -5 c12 -12 13 -23 6 -54 l-9 -38 -17 32 c-25 47 -45 63 -81 63 -57 0
-88 -43 -58 -80 9 -11 48 -32 86 -46 97 -35 126 -73 112 -148 -7 -36 -24 -55
-73 -80 -38 -19 -132 -20 -183 -2 -31 11 -35 17 -38 51 -5 52 12 65 33 26 27
-54 51 -71 97 -71 61 0 98 45 70 86 -10 16 -61 40 -100 49 -48 10 -102 67
-102 108 0 43 33 95 73 113 42 19 162 13 184 -9z m-3569 -1 c-10 -7 -18 -17
-18 -22 0 -11 75 -202 82 -210 7 -6 78 186 78 209 0 9 -8 21 -17 26 -14 8 -6
10 35 11 28 0 52 -4 52 -10 0 -5 -6 -10 -13 -10 -18 0 -25 -15 -112 -245 -42
-110 -86 -214 -98 -232 -24 -36 -55 -53 -94 -53 -50 0 -82 57 -51 88 19 19 70
14 95 -9 27 -26 46 -16 63 36 10 31 7 44 -33 137 -25 57 -59 138 -77 181 -18
43 -42 87 -53 98 -21 19 -20 19 79 19 84 -1 96 -3 82 -14z"/>
<path d="M1960 2761 l0 -81 63 0 c39 0 75 6 97 18 31 15 35 21 35 56 0 55 -32
77 -124 84 l-71 5 0 -82z"/>
<path d="M1960 2375 l0 -97 94 4 c84 3 97 6 120 27 42 40 30 116 -23 141 -14
7 -63 15 -108 18 l-83 5 0 -98z"/>
<path d="M2870 2759 l0 -81 79 3 c67 4 83 8 105 28 66 62 -5 131 -136 131
l-48 0 0 -81z"/>
<path d="M2877 2463 c-4 -3 -7 -47 -7 -96 l0 -89 101 4 c96 3 102 4 125 31 46
53 19 126 -54 147 -41 11 -155 14 -165 3z"/>
<path d="M2163 1763 c-23 -4 -23 -7 -23 -158 0 -166 0 -165 59 -165 17 0 37
10 52 26 23 24 24 33 24 141 0 113 0 115 -28 139 -28 23 -39 26 -84 17z"/>
<path d="M3480 1749 c-33 -37 -47 -115 -35 -201 12 -83 69 -128 126 -98 17 10
19 23 19 161 l0 149 -26 10 c-40 16 -53 12 -84 -21z"/>
<path d="M600 1800 l0 -113 42 6 c59 8 78 34 78 107 0 73 -19 99 -78 107 l-42
6 0 -113z"/>
<path d="M600 1555 l0 -115 43 0 c53 0 82 24 97 80 14 50 -2 113 -33 135 -12
8 -41 15 -64 15 l-43 0 0 -115z"/>
<path d="M1746 1764 c-16 -15 -20 -27 -31 -96 l-6 -38 66 0 c72 0 71 -1 66 80
-5 62 -58 92 -95 54z"/>
<path d="M4135 1760 c-11 -13 -20 -41 -23 -75 l-4 -55 66 0 66 0 0 40 c0 22
-7 56 -14 75 -12 29 -20 35 -44 35 -16 0 -37 -9 -47 -20z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
